<template>
  <el-dialog
    title="下达"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="接收人" prop="userIds" >
      <el-select style="width:100%" v-model="dataForm.userIds" placeholder="请选择" filterable clearable multiple
                       >
              <el-option v-for="item in userList" :key="item.userId" :label="item.username"
                         :value="item.userId">
              </el-option>
            </el-select>
    </el-form-item>
    <el-form-item label="联系方式" prop="phone">
      <el-input v-model="dataForm.phone" placeholder="联系方式"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit" >
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import {getUserPhone } from "@/api/kanban/majorWarning";
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        submitBool:true,
        limit:1,
        dataForm: {
          id: null,
          userId: '',
          reportContent: '',
          reportFile:'',
          files:[],
          uId:'',
          transmitCode:'',
          phone:'',
          userIds:[],
          mainId:''
        },
        cBool:false,
        fBool:false,
        bool:false,
        title:'',
        uID:'',
        label:'',
        userList:[],
        dataRule: {
          dealUser: [
            { userId: true, message: '接收人不能为空', trigger: 'blur' }
          ],
          userIds: [
            {required:true,message: '接收人不能为空',trigger: 'blur'}
          ],
          reportContent: [
            { required: true, message: '上报内容不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    components:{
      },
    computed: {
    regionId: {
      get() {
        const newregionId = this.$store.state.user.regionId;
        if(newregionId !== undefined){
          return Number(newregionId);
        }else{
          return ''
        }
      }
    },
    gridId: {
      get() {
        const newgridId = this.$store.state.user.gridId;
        if (newgridId !== undefined) {
            return Number(newgridId);
        } else {
          return ''
        }
      }
    },
    userName:{
      get(){
        const uName = this.$store.state.user.name;
        if(uName !== undefined){
          return String(uName); 
        }else{
          return ''
        }
      }
    },
    userId:{
      get(){
        const uID = this.$store.state.user.id;
        if(uID !== undefined){
          return String(uID); 
        }else{
          return ''
        }
      }
    }
  },
  watch: {
        'dataForm.uId': {
          handler: function (newVal, old) {
            if (newVal) {
              this.selectUserInfo(newVal)
            }
          },
          deep: true
        },
        'dataForm.userIds': {
          handler: function (newVal, old) {
            if (newVal) {
              this.getPhone(newVal)
            }
          },
          deep: true
        }
      },
    methods: {
      init (row,id,code) {
        this.dataForm.mainId = row.id  
        this.dataForm.uId = id
        this.dataForm.transmitCode = code
        this.title = row.bname
        this.uID = id
        this.selectUserInfo()
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()

            this.$http({
              url: this.$http.adornUrl(`/dataflow/olfmajortramit/info`),
              method: 'get',
              params: {mainId:this.dataForm.mainId,orgId:this.regionId,actionType:this.title}
            }).then(({data}) => {
              debugger
              if (data && data.code === 0) {
                this.dataForm.id = data.data.id
                var val = data.data.reportFile
                this.dataForm.userIds = data.data.userIds
                this.dataForm.reportContent = data.data.reportContent
                this.dataForm.dealResult = data.data.dealResult
                this.dataForm.files =val != null && val != '' && val != undefined?data.data.reportFile.split(','):[]
  
                if(data.data.userIds){
                  this.getPhone(data.data.userIds);
                }
              }
            })
        })
      },
      //获取人员信息列表
      selectUserInfo(){
         this.$http({
          url: this.$http.adornUrl(`/dataflow/olfmajorwarining/getUserInfo`),
          method: "get",
          params: this.$http.adornParams({
          'id': this.uID
        })
        }).then(({ data }) => {
          this.userList = data.data.filter(e => e.userId != this.uID)
        });
      },
      //获取人员信息列表
      selectUserInfos(){
         this.$http({
          url: this.$http.adornUrl(`/dataflow/olfmajorwarining/getUserInfo`),
          method: "get",
          params: this.$http.adornParams({
          'id': this.uID
        })
        }).then(({ data }) => {
          this.userList = data.data
        });
      },
      //获取联系方式
      getPhone(id){
        var ids = []
        ids = id
        this.$http({
          url: this.$http.adornUrl(`/dataflow/olfmajorwarining/getUserPhone`),
          method: "post",
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          this.dataForm.phone = data.data
        });
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            this.$http({
              url: this.$http.adornUrl(`/dataflow/olfmajortramit/update`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'mainId': this.dataForm.mainId,
                'userIds': this.dataForm.userIds,
                'reportContent': this.dataForm.reportContent,
                'files': this.dataForm.files,
                'transmitCode':this.dataForm.transmitCode,
                'transmitId':this.uID,
                'actionType':this.title
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500 
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).finally(()=>{
            	this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
